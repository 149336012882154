import React from 'react'
import { Link } from 'gatsby'
import { Menu, Transition } from '@headlessui/react'

const videos = [
  { name: 'Featured Videos', link: '/videos' },
  { name: 'Command Packages', link: '/videos/command-packages' },
  { name: 'Mortise Locks', link: '/videos/mortise-locks', },
  { name: 'Cylindrical Locks', link: '/videos/cylindrical-locks' },
  { name: 'Exit Device Kits', link: '/videos/exit-device-kits' },
  { name: 'Exit Devices', link: '/videos/exit-devices' },
  { name: 'Exit Trims', link: '/videos/exit-trims' },
  { name: 'Power Supplies', link: '/videos/power-supplies' },
  { name: 'Power Transfers', link: '/videos/power-transfers' },
  { name: 'Accessories', link: '/videos/accessories' },
]

const PageVideosNav = () => {

  return (
    <div className='mb-8'>
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button className="bg-red-900 group inline-flex items-center space-x-1 focus:outline-none px-4 py-2">
              <span className='font-display font-medium text-lg md:text-xl text-red-100 uppercase tracking-widest cursor-pointer'>Browse Videos</span>
              <svg className="text-white fill-current h-5 w-5 group-hover:text-white group-focus:text-white transition ease-in-out duration-150" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </Menu.Button>

            {/* Use the Transition + open render prop argument to add transitions. */}
            <Transition
              show={open}
              enter="transition ease-out duration-200"
              enterFrom="transform opacity-0 translate-y-1"
              enterTo="transform opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="transform opacity-100 translate-y-0"
              leaveTo="transform opacity-0 translate-y-1"
            >
              <Menu.Items static className="absolute w-11/12 md:max-w-xl -md:ml-4 left-0 right-0 mx-auto mt-3 transform focus:outline-none z-50">
              <div className="rounded-lg shadow-lg border-2">
                <div className="rounded-lg shadow-xs overflow-hidden">
                  <div className="z-20 relative grid grid-cols-2 gap-2 md:gap-8 bg-white px-5 py-6">
                    {videos.map((video, index) => {
                      return (
                        <Link
                          to={video.link}
                          key={index}
                          className="-m-3 p-3 flex items-start space-x-4 rounded-lg hover:bg-gray-200 transition ease-in-out duration-150"
                        >
                          <Menu.Item className="space-y-1">
                            <div className='flex flex-col'>
                              <p className="text-sm leading-6 font-bold text-red-900">
                                {video.name}
                              </p>
                            </div>
                          </Menu.Item>
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

export default PageVideosNav