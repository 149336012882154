import React from "react"
import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import VideosSectionUpdate from "../../components/sections/VideosSectionUpdate";

const MortiseLocksVideosPage = () => (
  <MainContainer>
    <HeadDataNew 
      title='Mortise Locks Videos Page' 
      description='A list of Command Access Mortise Locks videos.' 
    />

    <VideosSectionUpdate 
      title="Mortise Locks"
      category="Mortise Locks"
    />
  </MainContainer>
)

export default MortiseLocksVideosPage